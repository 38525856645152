import React from "react"
import {graphql} from "gatsby"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {Header} from "../components/Header";
import {IPage} from "../types";
import {Col, Container, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";

const containerStyle = {
    width: '100%',
    height: '600px'
};

const position = {
    lat: 52.352210, lng: 5.532077
}

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <Header>
                <StaticImage src={'../img/sfeer/Haven-Zeewolde.jpg'} aspectRatio={3} alt={'header'}/>
            </Header>
            <Container className={'my-5'}>
                <Row>
                    <Col md={6}>
                        <span className={'kalam'}>Zeewolde</span>
                        <h2>Zoveel in Zeewolde</h2>
                        <p className={'lead'}>
                        Zeewolde is ideaal voor starters. Het heeft een gezellig en bruisend centrum en door de centrale ligging ben je binnen no-time in de Randstad. Maar ook voor wie wil ontsnappen aan de hectiek van het dagelijkse leven kan zijn hart op: vanuit Binnengaats vaar je zo het Wolderwijd op of of struin je tussen de reeën en konikpaarden in het Horsterwold.
                        </p>
                        <h6>Een uitgekiende mix van winkelen en wonen</h6>
                        <p>
                            Zeewolde kent veel voorzieningen en ontspanningsmogelijkheden.
                            Het centrum biedt een uitgebreid aanbod van winkels voor je dagelijkse behoefte.
                            Maar ook als je ergens een hapje wilt eten of gezellig een drankje wilt doen, hoef je niet ver te zoeken.
                            Uiteraard is aan overige voorzieningen ook geen gebrek, met gezondheidscentra, basis- en voortgezet onderwijs en een rijk (sport)verenigingsleven.
                            Ook dat maak het wonen in Zeewolde zo fijn.
                        </p>
                    </Col>
                    <Col md={6}>
                    </Col>
                </Row>
                <StaticImage
                    src={'../img/sfeer/Fietsen-02.jpg'}
                    className={'my-5 image-shadow rounded'}
                    layout={'fullWidth'}
                    alt={'header'}
                />
                <div className={'my-5'}>
                    <p className={'text-primary text-center kalam'}>De schelpenbuurt, waar Binnengaats wordt ontwikkeld, <br />wordt een nieuwe waterrijke en groene wijk. Het is gelegen<br /> achter het nog in ontwikkeling zijnde Havenkwartier. </p>
                </div>
                <section className={'my-5'}>
                    <Row>
                        <Col md={'6'}>
                            <h6 className={'text-primary'}>Start van een nieuw begin</h6>
                            <p>De appartementen van Binnengaats worden samen met project Vaartsche Weelde gerealiseerd. Vaartsche Weelde bestaat uit 17 kadewoningen aan vaarwater. Deze twee projecten komen rondom een gezamenlijk groen plein: Het Kokkelhof.</p>
                        </Col>
                        <Col md={'6'}>
                            <p>Op het Kokkelhof is het een heerlijk samenkomen met buurtgenoten, kan je buiten een drankje doen en parkeer je er de auto of fiets. Wat het nog mooier maakt: hier staan 2 deelauto's, 2 deelfietsen en 2 deelscooters waar je als bewoner altijd gebruik van kunt maken. Handig voor een snelle boodschap of om bij vrienden op bezoek te gaan!</p>
                        </Col>
                    </Row>
                </section>
            </Container>
            <section className={'my-5'}>
                <LoadScript
                    googleMapsApiKey="AIzaSyAG1pfezyKDy1DuiStaBtiZVRa9YqxugPs"
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={position}
                        zoom={14}
                        mapTypeId={'terrain'}
                    >
                        { /* Child components, such as markers, info windows, etc. */ }
                        <Marker position={position} />
                    </GoogleMap>
                </LoadScript>
            </section>
            <Container
                as={'section'}
                fluid={'lg'}
                className={'my-5'}
            >
                <header>
                    <h2 className={'text-center'}>Situatie</h2>
                </header>
                <div className={'d-flex align-items-center'}>
                    <StaticImage className={'mx-auto rounded'} src={'../img/situatie.png'} alt={'situatie'} />
                </div>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "locatie"}) {
            childMarkdownRemark {
                ...Page
            }
        }
    }
`

